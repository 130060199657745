import React from "react";
import styled from "styled-components";

const Developoment = () => {
  return (
    <Container>
      <h1>Page is under development.</h1>
      <p>We will be back soon :)</p>
      <p>- Pouring Passions</p>
    </Container>
  );
};
const Container = styled.div`
  padding: 10%;
  padding-top: 15%;
  h1 {
    text-align: center;
    font-size: 5em;
  }
  p {
    text-align: center;
    font-size: 3em;
  }
  @media (max-width: 500px) {
    h1 {
      font-size: 3em;
    }
  }
`;

export default Developoment;
